import axios from 'axios'
import store from '@/store'
import router from '@/router'
import applyUrlTemplate from './applyUrlTemplate'
import applyDefaultParams from './applyDefaultParams'
import { applyCacheResuest, applyCacheResponse } from './cache'
import { msgBoxOkOptions } from '@/utils/utils'

import normalizeAxiosError from // SERVER_ERROR,
// TIMEOUT_ERROR,
// NETWORK_ERROR
'./normalizeAxiosError'

const API_TIMEOUT = 300000 // 300s
// const API_TIMEOUT = 5000 // 30s
const options = msgBoxOkOptions()

const apiV1 = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: API_TIMEOUT
})

apiV1.interceptors.request.use(applyCacheResuest)
apiV1.interceptors.request.use(applyUrlTemplate)
apiV1.interceptors.request.use(applyDefaultParams)

apiV1.interceptors.response.use(applyCacheResponse)
apiV1.interceptors.response.use(null, normalizeAxiosError)
apiV1.interceptors.response.use(null, err => {
  // console.log(process.env.VUE_APP_BASE_URL)
  // alert('서비스가 지연되고 있습니다. 잠시후 다시 접속해주세요.')
  if (err.status === 400) {
    router.app.$bvModal.msgBoxOk('세션이 만료되었습니다. 다시 로그인해주세요', options)
    router.push('/login')
  } else {
    router.app.$bvModal.msgBoxOk('서비스가 지연되고 있습니다. 잠시후 다시 접속해주세요.', options)
    return Promise.reject(err)
  }
})

apiV1.interceptors.response.use(
  response => {
    if (response.data && response.data.info && response.data.info.result && response.data.info.result.code === 'FAIL') {
      if (response.data.info.result.message === 'permission denied') {
        router.app.$bvModal.msgBoxOk('접근 권한이 없습니다.', options).then(() => {
          router.push({ path: store.getters['common/getUserInitPage'] })
        })
      } else if (response.data.error.code === 201) {
        router.app.$bvModal.msgBoxOk('세션 시간이 만료 되었습니다.', options).then(() => {
          localStorage.removeItem('userName')
          store.dispatch('common/logout')
          router.push('/login')
        })
      }
    } else {
      if (store.getters['common/getRefreshTime'] === '') {
        if (response.config.url !== 'user/refresh-token') store.dispatch('common/refreshToken')
      } else {
        let afterRefreshTime = new Date().getTime()
        let beforeRefreshTime = store.getters['common/getRefreshTime'].getTime()
        // router refresh 후 3초 지났을때 refresh 하도록 간격 제어
        if (afterRefreshTime - beforeRefreshTime >= 3000) {
          if (response.config.url !== 'user/refresh-token') store.dispatch('common/refreshToken')
        }
      }
      return response
    }
  },
  async error => {
    router.app.$bvModal.msgBoxOk('서비스가 지연되고 있습니다. 잠시후 다시 접속해주세요.', options)
    return Promise.reject(error)
  }
)
export default apiV1
