import { createApiV1Store } from '@/api'
import apiV1 from '@/api/apiV1'
import { hourData, msgBoxOkOptions } from '@/utils/utils'
import config from '@/config'
import router from '@/router'
const options = msgBoxOkOptions()
const apiStore = createApiV1Store([
  {
    action: '_login',
    path: `user/login`,
    method: 'post',
    cacheable: false,
    onSuccess (state, payload, commit) {
      if (payload.data.code && payload.data.code === 'error') {
        router.app.$bvModal.msgBoxOk('로그인 정보를 다시 확인해주세요', options)
      } else {
        sessionStorage.setItem('userName', payload.data.userName)
        sessionStorage.setItem('accessToken', payload.data.accessToken)
        sessionStorage.setItem('guest', payload.data.guest)
        state.userName = payload.data.userName
        state.accessToken = payload.data.accessToken
        apiV1.defaults.headers.common['authorization'] = payload.data.accessToken ? payload.data.accessToken : ''
        router.push('/main')
      }
    },
    onError (state, error) {
      state.userName = ''
      state.accessToken = ''
      delete sessionStorage.userName
      delete sessionStorage.accessToken
      delete apiV1.defaults.headers.common['authorization']
    }
  },
  {
    action: '_logout',
    path: `user/logout`,
    method: 'post',
    cacheable: false,
    onSuccess (state, payload) {
      state.userName = ''
      state.accessToken = ''
      delete sessionStorage.userName
      delete sessionStorage.accessToken
      delete apiV1.defaults.headers.common['authorization']
      router.push('/login')
    },
    onError (state, error) {
      state.userName = ''
      state.accessToken = ''
      delete sessionStorage.userName
      delete sessionStorage.accessToken
      delete apiV1.defaults.headers.common['authorization']
      router.push('/login')
    }
  },
  {
    action: '_refreshToken',
    path: `user/refresh-token`,
    method: 'post',
    cacheable: false
  },
  {
    action: '_holiday',
    path: `report/holiday`,
    method: 'get',
    cacheable: false
  }
])

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    loading: false,
    modalInfo: null,
    accessToken: null,
    userName: null,
    refreshTime: null,
    holiday: []
  },
  actions: {
    login ({ state, dispatch, commit }, data) {
      return dispatch('_login', { data: data })
    },
    logout ({ dispatch }) {
      return dispatch('_logout')
    },
    refreshToken ({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('accessToken')) {
          apiV1.defaults.headers.common['authorization'] = sessionStorage.getItem('accessToken')


          dispatch('_refreshToken').then(res => {
            if (res.data === 'success') {
              resolve(res)
              commit('SET_REFRESH_TIME', new Date())
            } else {
              commit('CREAR_USER')
              router.push('/login')
            }
          }, error => {
            reject(error)
          })
        } else {
          if (router.history.current.path !== '/login') {
            commit('CREAR_USER')
            router.app.$bvModal.msgBoxOk('세션이 만료되었습니다. 로그인 화면으로 이동합니다.', options).then(() => {
              router.push('/login')
            })
          }
        }
      })
    },
    async fetchHoliday ({ dispatch, commit }, params) {
      return new Promise((resolve, reject) => {
        dispatch('_holiday', { params: params }).then(res => {
          commit('SET_HOLIDAY', { items: res.data.Body })
          resolve(res)
        }, error => {
          reject(error)
        })
      })
    },
  },
  mutations: {
    SET_HOLIDAY (state, data) {
      state.holiday = state.holiday.concat(data.items)
      sessionStorage.setItem('holiday', JSON.stringify(state.holiday))
    },
    SET_COMMON_MODAL (state, params) {
      state.modalInfo = params
    },
    SET_LOADING (state, params) {
      
      state.loading = params
    },
    CREAR_USER (state) {
      state.userName = ''
      state.accessToken = ''
      delete sessionStorage.userName
      delete sessionStorage.accessToken
      delete apiV1.defaults.headers.common['authorization']
    },
    SET_REFRESH_TIME (state, data) {
      localStorage.setItem('refreshTime', data)
      state.refreshTime = data
    },
  },
  getters: {
    displayListClass: (state, getters) => data => {
      if (getters.loading && data === null) {
        return 'loading'
      } else if (!getters.loading && data && data.metrics.length === 0) {
        return 'nodata'
      } else {
        return ''
      }
    },
    displayClass: (state, getters) => data => {
      if (getters.loading && data === null) {
        return 'loading'
      } else if (!getters.loading && data === null) {
        return 'nodata'
      } else {
        return ''
      }
    },
    modalInfo: state => {
      return state.modalInfo
    },
    loading: state => {
      return state.loading
    },
    hourData: () => {
      return hourData()
    },
    keywords: () => {
      return JSON.parse(JSON.stringify(config.defines.commons.keywords))
    },
    devices: () => {
      return JSON.parse(JSON.stringify(config.defines.commons.devices))
    },
    medias: () => {
      return JSON.parse(JSON.stringify(config.defines.commons.medias))
    },
    searchTypes: () => {
      return JSON.parse(JSON.stringify(config.defines.commons.searchTypes))
    },
    criterias: () => {
      return JSON.parse(JSON.stringify(config.defines.commons.criterias))
    },
    userName: () => { return sessionStorage.getItem('userName') },
    isLogined: () => { return !(sessionStorage.getItem('accessToken') === '' || sessionStorage.getItem('accessToken') === undefined || sessionStorage.getItem('accessToken') === null) },
    getRefreshTime: (state) => state.refreshTime || '',
    getUserInitPage: (state, getters) => {
      if (sessionStorage.getItem('accessToken') === '' || sessionStorage.getItem('accessToken') === undefined || sessionStorage.getItem('accessToken') === null) {
        return '/login'
      } else {
        return '/sample/table'
      }
    },
    holiday: state => {
      return JSON.parse(sessionStorage.getItem('holiday')) || state.holiday
    },
  }
}
