import createApiStoreCreator from './createApiStoreCreator'

import apiV1 from './apiV1'

const createApiV1Store = createApiStoreCreator(apiV1)

export {
  apiV1 as default,
  apiV1,
  createApiV1Store
}
