import { createApiV1Store } from '@/api'

const apiStore = createApiV1Store([])

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    userInfo: null,
    globalConfig: null,
    accessToken: null,
    refreshTime: null,
    menuInfo: [],
    currentMenuInfo: null,
    roleInfo: null,
    isReadOnlyInfo: null,
    isReadWriteInfo: null,
    isDownloadInfo: null
  },
  actions: {
  },
  mutations: {
  },
  getters: {
    getUserInfo: (state) => state.userInfo || '',
    getMenuInfo: (state) => state.menuInfo || '',
    getCurrentMenuInfo: (state) => state.currentMenuInfo || '',
  }
}
