export function addDay(date, cnt, separator) {
  if (date.constructor !== Date) date = new Date(date.replace(/-/g, '/'))
  if (date.constructor !== Date) {
    return ''
  }

  date.setDate(date.getDate() + cnt)

  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day

  return reDate
}

export function addMonth(date, cnt, separator) {
  if (date.constructor !== Date) {
    return ''
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }

  let newDate = new Date(date.getFullYear(), date.getMonth() + cnt, date.getDate())
  const newDateEndDay = new Date(date.getFullYear(), date.getMonth() + cnt + 1, 0)
  if (newDate.getMonth() !== newDateEndDay.getMonth()) {
    newDate = newDateEndDay
  }
  let month = newDate.getMonth() + 1
  let day = newDate.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  return newDate.getFullYear() + separator + month + separator + day
}

export function moveDay(date, separator, moveCnt) {
  if (date.constructor !== Date) {
    if (date) {
      date = new Date(date)
    } else {
      return ''
    }
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }

  date.setDate(date.getDate() + moveCnt)

  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  return date.getFullYear() + separator + month + separator + day
}

export function moveMonth(date, separator, moveCnt) {
  if (date.constructor !== Date) {
    if (date) {
      date = new Date(date)
    } else {
      return ''
    }
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }

  let newDate = new Date(date.getFullYear(), date.getMonth() + moveCnt, date.getDate())

  const newDateEndDay = new Date(date.getFullYear(), date.getMonth() + moveCnt + 1, 0)
  if (newDate.getMonth() !== newDateEndDay.getMonth()) {
    newDate = newDateEndDay
  }
  let month = newDate.getMonth() + 1
  let day = newDate.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  return newDate.getFullYear() + separator + month + separator + day
}

export function getStrDate(date, separator) {
  if (date === null || date === undefined || date === '') {
    return ''
  }
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (date.constructor !== Date) {
    return ''
  }

  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day

  return reDate
}

export function getStrYM(date, separator) {
  if (date === null || date === undefined || date === '') {
    return ''
  }
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (date.constructor !== Date) {
    return ''
  }

  let month = date.getMonth() + 1

  if (month < 10) {
    month = '0' + month
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month

  return reDate
}

export function getStrYMDHM(date, separator) {
  if (date === null || date === undefined || date === '') {
    return ''
  }
  date = new Date(date.replace(/-/g, '/'))
  if (date.constructor !== Date) {
    return ''
  }

  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  if (separator === '') {
    const reDate = date.getFullYear() + separator + month + separator + day + hours + minutes
    return reDate
  } else {
    const reDate = date.getFullYear() + separator + month + separator + day + ' ' + hours + ':' + minutes
    return reDate
  }
}

export function getStrHM(date) {
  if (date === null || date === undefined || date === '') {
    return ''
  }
  date = new Date(date.replace(/-/g, '/'))
  if (date.constructor !== Date) {
    return ''
  }

  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  const reDate = hours + ':' + minutes
  return reDate
}

export function getStrYMDHMSS(date, separator) {
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }

  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day + ' ' + hours + ':' + minutes + ':' + seconds

  return reDate
}

export function getToday() {
  return getStrDate(new Date())
}

export function getTodayTime() {
  return getStrYMDHMSS(new Date())
}

export function getFirstAndEndDay(mm, separator) {
  const nowdays = new Date()
  let year = nowdays.getFullYear()
  let month = nowdays.getMonth() + 1

  if (month <= mm) {
    year = year - 1
    month = month - mm + 12
  } else {
    month = month - mm
  }

  if (month < 10) {
    month = '0' + month
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }

  const startYmd = year + separator + month + separator + '01'

  const myDate = new Date(year, month, 0)
  const endYmd = year + separator + month + separator + myDate.getDate()

  return { startYmd, endYmd }
}

export function getSixMonths() {
  const sixMonths = []
  for (let index = 0; index < 12; index++) {
    const nowDate = new Date()
    const newDate = new Date(nowDate.getFullYear(), nowDate.getMonth() - index, 1)
    sixMonths.push(newDate.getFullYear() + '년 ' + (newDate.getMonth() + 1) + '월')
  }
  return sixMonths
}

export function isYearEndDay() {
  const date = new Date()
  if (date.getMonth() === 11 && date.getDate() === 31) {
    return true
  } else {
    return false
  }
}

export function getJanuary5() {
  const date = new Date()
  return date.getFullYear() + 1 + '-01-05 23:59:59'
}

export function getEndDayForYear() {
  const date = new Date()
  return date.getFullYear() + '-12-31 23:59:59'
}

export function getStrMDW(date, separator, lan) {
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (date.constructor !== Date) {
    return ''
  }
  let weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  if (lan === 'kr') weeks = ['일', '월', '화', '수', '목', '금', '토']
  if (separator) {
    return `${date.getMonth() + 1 + separator + date.getDate()} (${weeks[date.getDay()]})`
  } else {
    return date.getMonth() + 1 + '.' + date.getDate() + ' ' + weeks[date.getDay()]
  }
}

export function getStrMD(date, separator) {
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (date.constructor !== Date) {
    return ''
  }
  if (separator) {
    return `${date.getMonth() + 1 + separator + date.getDate()}`
  } else {
    return date.getMonth() + 1 + '.' + date.getDate()
  }
}

export function isOverDay(date) {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const d = new Date(date.replace(/-/g, '/') + ' GMT+9') - new Date().getTime()
  return d > 0 && d <= ONE_DAY
}

export function within30Days(date) {
  const ONE_MONTH = 1000 * 60 * 60 * 24 * 30
  const d = new Date(date.replace(/-/g, '/') + ' GMT+9') - new Date().getTime()
  return d > 0 && d <= ONE_MONTH
}

export function within7Days(date) {
  const ONE_WEEK = 1000 * 60 * 60 * 24 * 7
  const d = new Date(date.replace(/-/g, '/') + ' GMT+9') - new Date().getTime()
  return d > 0 && d <= ONE_WEEK
}

export function dateFormat(date, separator) {
  if (date) {
    if (date.constructor !== Date) {
      date = new Date(date.replace(/-/g, '/'))
    }

    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = date.getDate()
    d = d < 10 ? '0' + d : d
    return y + separator + m + separator + d
  }
}

export function getDateFormat(date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))

    let y = slaDate.getFullYear()
    let m = slaDate.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = slaDate.getDate()
    d = d < 10 ? '0' + d : d
    return y + '년 ' + m + '월 ' + d + '일'
  }
}

export function getDateFormatMD(date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))
    let m = slaDate.getMonth() + 1
    let d = slaDate.getDate()
    return m + '월 ' + d + '일 ' + ' 오픈예정'
  }
}

export function getDateFormatYMDHM(date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))

    let y = slaDate.getFullYear()
    let m = slaDate.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = slaDate.getDate()
    d = d < 10 ? '0' + d : d
    let hours = slaDate.getHours()
    let minutes = slaDate.getMinutes()
    minutes = minutes < 10 ? '0' + minutes : minutes
    return y + '년 ' + m + '월 ' + d + '일' + (hours < 12 ? ' 오전 ' : ' 오후 ') + (hours < 12 ? hours : hours - 12) + ':' + minutes
  }
}

export function getDateFormatMDHM(date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))
    let m = slaDate.getMonth() + 1
    let d = slaDate.getDate()
    let hours = slaDate.getHours()
    let minutes = slaDate.getMinutes()
    minutes = minutes < 10 ? '0' + minutes : minutes
    return m + '월 ' + d + '일' + (hours < 12 ? ' 오전 ' : ' 오후 ') + (hours < 12 ? hours : hours - 12) + ':' + minutes + ' 오픈예정'
  }
}

export function getDateFormatForAlert() {
  let slaDate = new Date()

  let y = slaDate.getFullYear()
  let m = slaDate.getMonth() + 1
  m = m < 10 ? '0' + m : m
  let d = slaDate.getDate()
  d = d < 10 ? '0' + d : d
  let hours = slaDate.getHours()
  let minutes = slaDate.getMinutes()
  return y + '.' + m + '.' + d + ' ' + hours + ':' + minutes
}

export function getDateForTimeDeal(date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))
    let m = slaDate.getMonth() + 1
    let d = slaDate.getDate()
    let hours = slaDate.getHours()
    // let minutes = slaDate.getMinutes()
    // minutes = minutes < 10 ? ('0' + minutes) : minutes
    let result = {
      mmdd: m + '/' + d,
      hours: hours < 12 ? '오전' : '오후',
      hh: hours < 12 ? hours : hours - 12
    }
    return result
  }
}

export function getWeek(dowOffset) {
  /* getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof dowOffset === 'number' ? dowOffset : 0 // default dowOffset to zero
  var newYear = new Date(this.getFullYear(), 0, 1)
  var day = newYear.getDay() - dowOffset // the day of week the year begins on
  day = day >= 0 ? day : day + 7
  var daynum = Math.floor((this.getTime() - newYear.getTime() - (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
  var weeknum
  // if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      let nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /* if the next year starts before the middle of
        the week, it is week #1 of that year */
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

export function getMonday(d, obj) {
  d = new Date(d)
  let day = d.getDay()

  let weeks = []
  let weekdayKr = ['월', '화', '수', '목', '금']
  let weekdayEn = ['MON', 'TUE', 'WED', 'THU', 'FRI']
  let monday = new Date(d.setDate(d.getDate() - day + (day === 0 ? -6 : 1))) // adjust when day is sunday\
  for (let i in [0, 1, 2, 3, 4]) {
    let data = {
      weekdayKr: weekdayKr[i],
      weekdayEn: weekdayEn[i],
      date: getStrDate(new Date(monday.setDate(monday.getDate() + (i === '0' ? 0 : 1))), '-')
    }
    if (obj) Object.assign(data, obj)
    weeks.push(data)
  }
  return weeks
}

export function diffMinutes(date2, date1) {
  if (!date1 || !date2) return
  let dt1 = new Date(date1)
  let dt2 = new Date(date2)

  var diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

export function diffDays(date2, date1) {
  if (!date1 || !date2) return
  let dt1 = new Date(date1)
  let dt2 = new Date(date2)

  var diff = (dt2.getTime() - dt1.getTime()) / (1000 * 3600 * 24)
  return Math.abs(Math.round(diff))
}

export function getDayOfTheWeek(dateStr) {
  var yyyyMMdd = String(dateStr).replace(/-/g, '')
  var sYear = yyyyMMdd.substring(0, 4)
  var sMonth = yyyyMMdd.substring(4, 6)
  var sDate = yyyyMMdd.substring(6, 8)

  var date = new Date(Number(sYear), Number(sMonth) - 1, Number(sDate))

  var week = ['일', '월', '화', '수', '목', '금', '토']
  return week[date.getDay()] + '요일'
}
