import { utils, writeFileXLSX } from 'xlsx'
import _ from 'lodash'
import { getToday } from '@/utils/DateUtils'

export default {
    namespaced: true,
    actions: {
        downloadExcelFromHtmlTable ({ state, getters, dispatch, commit }, { id, fileName }) {
            const element = document.getElementById(id)
            const workbook = utils.table_to_book(element)
            writeFileXLSX(workbook, `${fileName}_${getToday()}.xlsx`)
            return
        },
        downloadExcelFromData ({ state, getters, dispatch, commit }, params) {
            const header = params.fields.reduce((acc, cur) => {
                if (!acc) {
                    acc = {}
                }
                acc[cur.key] = cur.label
                return acc
            }, {})
            const sheetData = []
            sheetData.push(Object.values(header))
            params.items.forEach((item) => {
                const data = []
                Object.keys(header).forEach((key) => {
                    data.push(item[key])
                })
                sheetData.push(data)
            })
            const worksheet = utils.aoa_to_sheet(sheetData)
            const workbook = utils.book_new()
            utils.book_append_sheet(workbook, worksheet, "Sheet1")
            writeFileXLSX(workbook, `${params.fileName}.xlsx`)
        }
    }
}