import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from '@/router/routes'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  scrollBehavior(to, from, savedPsition) {
    if (from.meta.hasOwnProperty('pageCode') && to.meta.hasOwnProperty('pageCode')) {
      return null
    }
    return { x: 0, y: 0 }
  },
  routes: [
    ...routes,
    {
      path: '/',
      redirect: 'login'
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== '/login') {
    store
      .dispatch(`common/refreshToken`)
      .then(res => {
        if (res.data.error) {
          return next('/login')
        } else {
          store.commit('filterParam/RESET_PARAMS')
          return initialize()
        }
      })
      .catch(() => {
        return initialize()
      })
  } else {
    return next()
  }
  const initialize = () => {
    if ((to.path === '/error-404' && from.path === '/') || (to.path === undefined && from.path === undefined)) {
      return next('/')
    } else {
      return next()
    }
  }
})


export default router

