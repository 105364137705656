import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import HighchartsVue from 'highcharts-vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
// Vue.use(ToastPlugin)
// Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(HighchartsVue)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

if (process.env.VUE_APP_SENTRY && process.env.VUE_APP_SENTRY !== '') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
