let config = {
  defines: {
    commons: {
      keywords: [
        { id: 0, code: 'nkw-a001-01-000000431138660', name: '자동차보험', device: 'MOBILE', group: ['ageRank', 'bidPrice', 'rival', 'bidLog', 'keywordPerformance'] },
        { id: 1, code: 'nkw-a001-01-000000431138634', name: '다이렉트자동차보험', device: 'MOBILE', group: ['ageRank', 'bidPrice', 'rival', 'bidLog', 'keywordPerformance'] },
        { id: 2, code: 'nkw-a001-01-000000431138662', name: '자동차보험료비교견적사이트', device: 'MOBILE', group: ['ageRank', 'bidPrice', 'bidLog', 'keywordPerformance'] },
        { id: 3, code: 'nkw-m001-01-000000648045081', name: '자동차보험', device: 'PC', group: ['ageRank', 'bidPrice', 'rival', 'bidLog', 'keywordPerformance'] },
        { id: 4, code: 'nkw-m001-01-000000648045088', name: '다이렉트자동차보험', device: 'PC', group: ['ageRank', 'bidPrice', 'rival', 'bidLog', 'keywordPerformance'] },
        { id: 5, code: 'nkw-m001-01-000001124023930', name: '자동차보험료비교견적사이트', device: 'PC', group: ['ageRank', 'bidPrice', 'bidLog', 'keywordPerformance'] }
      ],
      hours: [
        { id: 'all', code: 'all', name: '전체', group: ['bidPrice'] },
        { id: 0, code: '00', name: '00시', group: ['bidLog', 'ageRank'] },
        { id: 1, code: '01', name: '01시', group: ['bidLog', 'ageRank'] },
        { id: 2, code: '02', name: '02시', group: ['bidLog', 'ageRank'] },
        { id: 3, code: '03', name: '03시', group: ['bidLog', 'ageRank'] },
        { id: 4, code: '04', name: '04시', group: ['bidLog', 'ageRank'] },
        { id: 5, code: '05', name: '05시', group: ['bidLog', 'ageRank'] },
        { id: 6, code: '06', name: '06시', group: ['bidLog', 'ageRank'] },
        { id: 7, code: '07', name: '07시', group: ['bidLog', 'status', 'ageRank'] },
        { id: 8, code: '08', name: '08시', group: ['bidLog', 'ageRank'] },
        { id: 9, code: '09', name: '09시', group: ['bidLog', 'ageRank'] },
        { id: 10, code: '10', name: '10시', group: ['bidLog', 'bidPrice'] },
        { id: 11, code: '11', name: '11시', group: ['bidLog', 'ageRank'] },
        { id: 12, code: '12', name: '12시', group: ['bidLog', 'ageRank'] },
        { id: 13, code: '13', name: '13시', group: ['bidLog', 'bidPrice'] },
        { id: 14, code: '14', name: '14시', group: ['bidLog', 'ageRank'] },
        { id: 15, code: '15', name: '15시', group: ['bidLog', 'ageRank'] },
        { id: 16, code: '16', name: '16시', group: ['bidLog', 'ageRank'] },
        { id: 17, code: '17', name: '17시', group: ['bidLog', 'bidPrice', 'status'] },
        { id: 18, code: '18', name: '18시', group: ['bidLog', 'ageRank'] },
        { id: 19, code: '19', name: '19시', group: ['bidLog', 'ageRank'] },
        { id: 20, code: '20', name: '20시', group: ['bidLog', 'ageRank'] },
        { id: 21, code: '21', name: '21시', group: ['bidLog', 'ageRank'] },
        { id: 22, code: '22', name: '22시', group: ['bidLog', 'ageRank'] },
        { id: 23, code: '23', name: '23시', group: ['bidLog', 'ageRank'] }
      ],
      devices: [
        { id: 0, code: 'PC', name: 'PC' },
        { id: 1, code: 'MOBILE', name: 'MOBILE' }
      ],
      medias: [
        { id: 0, code: '네이버', name: '네이버' },
        { id: 1, code: '다음', name: '다음' },
        { id: 2, code: '구글', name: '구글' }
      ],
      searchTypes: [
        { id: 0, code: '자동완성', name: '자동완성어' },
        { id: 2, code: '연관검색', name: '연관검색어' }
      ],
      criterias: [
        { id: 0, code: 'default', name: '일별' },
        { id: 1, code: 'weekly', name: '주별' },
        { id: 2, code: 'monthly', name: '월별' }
      ]
    },
    fields: {
      bidLog: [
        {
          key: 'CalcDate',
          label: '시간',
          class: 'mw-5'
        },
        {
          key: '현재입찰액',
          label: '직전 입찰가'
        },
        {
          key: '변경입찰액',
          label: '현재 입찰가'
        },
        {
          key: '현재순위',
          label: '현재 순위',
          class: 'mw-5'
        },
        {
          key: '목표순위',
          label: '목표 순위',
          class: 'mw-5'
        }
      ],
      bidPrice: [
        {
          key: '키워드',
          label: '키워드',
          class: 'mw-10'
        },
        {
          key: '유형',
          label: '유형',
          class: 'mw-10'
        },
        {
          key: '날짜',
          label: '일자',
          class: 'mw-10'
        },
        {
          key: '시간',
          label: '시간',
          class: 'mw-5'
        },
        {
          key: '순위',
          label: '순위',
          class: 'mw-5'
        },
        {
          key: '입찰가',
          label: 'CPC',
          class: 'mw-10'
        }
      ],
      bidStatus: [
        {
          key: '날짜',
          label: '일자',
          class: 'mw-10'
        },
        {
          key: '시간',
          label: '시간'
        },
        {
          key: '디바이스',
          label: '디바이스'
        },
        {
          key: '키워드',
          label: '키워드'
        },
        {
          key: 'LinkURL',
          label: '노출현황'
        },
        {
          key: '광고여부',
          label: '광고여부',
          class: 'mw-5'
        }
      ],
      google: [
        {
          key: '키워드',
          label: '키워드',
          stickyColumn: true,
          class: 'mw-10'
        }
      ],
      naverQuality: [
        {
          key: 'Keyword',
          label: '키워드',
          stickyColumn: true,
          class: 'mw-10'
        }
      ],
      autoKeyword: [
        {
          key: '키워드',
          label: '키워드',
          stickyColumn: true,
          thClass: 'text-center',
          tdClass: 'sticky-column text-left',
          class: 'mw-15'
        },
        {
          key: '순위',
          label: '순위',
          thClass: 'text-center',
          tdClass: 'sticky-column text-left',
          stickyColumn: true,
          class: 'mw-5'
        }
      ],
      rival: [
        {
          key: '업체명',
          label: '업체명',
          stickyColumn: true,
          class: 'mw-10 text-center'
        },
        {
          key: 'SHour',
          label: 'SHour',
          class: 'mw-5 text-center',
          stickyColumn: true
        },
        {
          key: '평균순위',
          label: '평균순위',
          class: 'mw-5 text-center',
          stickyColumn: true
        }
      ],
      trendReports: [
        {
          key: 'Keyword',
          label: '키워드',
          stickyColumn: true,
          class: 'mw-15',
          thClass: 'text-center',
          tdClass: 'text-left'
        }
      ],
      keywordPerformance: [
        {
          key: '구분',
          label: '구분',
          class: 'mw-15',
          thClass: 'text-center',
          tdClass: 'text-left'
        },

        {
          key: '키워드',
          label: '키워드',
          class: 'mw-10',
          thClass: 'text-center',
          tdClass: 'text-left'
        },
        {
          key: '평균노출순위',
          label: '평균 순위',
          class: 'mw-5 text-center',
          thClass: 'word-keep-all'
        },
        {
          key: '노출',
          label: '노출',
          class: 'mw-5 text-center'
        },
        {
          key: '클릭',
          label: '클릭(매체)',
          class: 'mw-5 text-center',
          thClass: 'word-keep-all'
        },
        {
          key: '접속',
          label: '접속(인사이트)',
          class: 'mw-5 text-center',
          thClass: 'word-keep-all'
        },
        {
          key: 'CTR',
          label: 'CTR',
          class: 'mw-5 text-center'
        },
        {
          key: 'CPC',
          label: 'CPC',
          class: 'mw-5 text-center'
        },
        {
          key: '광고비',
          label: '광고비',
          class: 'mw-5 text-center'
        },
        {
          key: '정보입력',
          label: '정보입력',
          class: 'mw-10 text-center'
        },
        {
          key: '정보입력률',
          label: '정보입력률',
          class: 'mw-10 text-center'
        },
        {
          key: '설계완료',
          label: '설계완료',
          class: 'mw-10 text-center'
        },
        {
          key: '신타2',
          label: '신타2',
          class: 'mw-5 text-center'
        },
        {
          key: '완료율',
          label: '완료율',
          class: 'mw-5 text-center'
        },
        {
          key: 'CPA',
          label: 'CPA(신타2)',
          class: 'mw-5 text-center',
          thClass: 'word-keep-all'
        }
      ]
    },
    chartColor: {
      rival: [
        { key: '삼성', color: '#0841DC' },
        { key: '롯데', color: '#D9D9D9' },
        { key: '메리츠', color: '#EF3823' },
        { key: '캐롯', color: '#FF4600' },
        { key: '하나손보', color: '#20B7A4' },
        { key: '한화', color: '#FF6600' },
        { key: '현대', color: '#F18800' },
        { key: '흥국', color: '#E4007F' },
        { key: 'AXA', color: '#010190' },
        { key: 'DB', color: '#00854A' },
        { key: 'KB', color: '#FDAF03' }
      ]
    }
  }
}

export default config
