import { createApiV1Store } from '@/api'
const apiStore = createApiV1Store([
  {
    action: '_fetchProjects',
    path: `mweb/project`,
    method: 'get',
    cacheable: false
  },
  {
    action: '_fetchMainList',
    path: `mweb/search-keyword`,
    method: 'get',
    cacheable: false
  },
  {
    action: '_fetchLogKeywords',
    path: `mweb/bidding/log/keyword`,
    method: 'get',
    cacheable: false
  },
  {
    action: '_fetchLogList',
    path: `mweb/bidding/log/list`,
    method: 'get',
    cacheable: false
  },
  {
    action: '_fetchBiddingPriceList',
    path: `mweb/bidding-price/keyword`,
    method: 'get',
    cacheable: false
  },
  {
    action: '_updateBiddingPriceList',
    path: `mweb/bidding-price/update-keyword`,
    method: 'put',
    cacheable: false
  }
])

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    projects: undefined,
    logKeywords: {},
    selectedLogKeyword: undefined
  },
  getters: {
    projectBodies(state) {
      return state.projects?.Body || []
    }
  },
  mutations: {
    SET_PROJECTS(state, params) {
      state.projects = params
    },
    SET_LOG_KEYWORDS(state, { project_seq, data }) {
      state.logKeywords[project_seq] = data
    },
    SELECT_LOG_KEYWORD(state, nccKeywordidId) {
      state.selectedLogKeyword = nccKeywordidId
    }
  },
  actions: {
    fetchProjects({ state, dispatch, commit }) {
      return state.projects
        ? Promise.resolve(state.projects)
        : dispatch('_fetchProjects', { params: { corp_seq: 95, appView: 'Y' } }).then(res => {
            commit('SET_PROJECTS', res.data)
            return res
          })
    },
    fetchMainList({ state, dispatch, commit }, project_seq) {
      return dispatch('_fetchMainList', { params: { project_seq } })
    },
    fetchLogKeywords({ state, dispatch, commit }, project_seq) {
      return state.logKeywords[project_seq]
        ? state.logKeywords[project_seq]
        : dispatch('_fetchLogKeywords', { params: { project_seq } }).then(res => {
            commit('SET_LOG_KEYWORDS', { project_seq, data: res.data })
            return res
          })
    },
    fetchLogList({ state, dispatch, commit }, params) {
      return dispatch('_fetchLogList', { params })
    },
    fetchBiddingPriceList({ state, dispatch, commit }, project_seq) {
      return dispatch('_fetchBiddingPriceList', { params: { project_seq } })
    },
    updateBiddingPriceList({ dispatch }, data) {
      return dispatch('_updateBiddingPriceList', {data})
    }
  }
}
