<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <div class="content_loading" v-if="loading"></div>
    <component :is="layout">
      <router-view />
    </component>
    <b-modal ref="normalModal" id="normalModal" size="sm" dialog fade centered hide-header v-if="customModalInfo && 'normal' === customModalInfo.type">
      <template>
        <p class="alert_txt text-center" v-html="modalInfo.message"></p>
      </template>
      <template #modal-footer>
        <div class="text-center btn_marBox">
          <button type="button" class="btn btn-primary waves-effect waves-float waves-light" data-bs-dismiss="modal" @click.prevent="closeCommonModal">확인</button>
        </div>
      </template>
    </b-modal>
    <b-modal ref="normalModal" id="normalModal" size="sm" dialog fade centered hide-header v-if="customModalInfo && 'confirm' === customModalInfo.type">
      <template>
        <p class="alert_txt text-center" v-html="modalInfo.message"></p>
      </template>
      <template #modal-footer>
        <div class="text-center btn_marBox">
          <button type="button" class="btn btn-primary waves-effect waves-float waves-light" data-bs-dismiss="modal" @click.prevent="clickConfirm($event), closeCommonModal">확인</button>
          <button type="button" class="btn btn-outline-primary waves-effect" data-bs-dismiss="modal" @click.prevent="closeCommonModal">취소</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { mapGetters, mapMutations } from 'vuex'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { msgBoxOkOptions } from '@/utils/utils'
import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutHorizontal = () => import('@/layouts/horitontal/LayoutHorizontal.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
    LayoutHorizontal
  },
  data() {
    return {
      msgBoxOkOptions: msgBoxOkOptions()
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters('common', ['loading', 'modalInfo', 'getUserInitPage', 'isLogined']),
    customModalInfo: {
      get() {
        if (this.modalInfo) {
          this.$nextTick(() => {
            this.$refs['normalModal'].show()
          })
          return this.modalInfo
        }
      }
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    }
  },
  created() {
    if (!this.isLogined) {
      this.$router.push({ path: this.getUserInitPage })
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    ...mapMutations('common', ['SET_LOADING', 'SET_COMMON_MODAL']),
    closeCommonModal() {
      this.SET_COMMON_MODAL(null)
      this.$refs['normalModal'].hide()
    },
    clickConfirm(event) {
      if (this.modalInfo.goFocus) {
        this.modalInfo.goFocus.focus()
      }
      if (this.modalInfo.callbackFn) {
        this.modalInfo.callbackFn(event)
      }
      if (this.modalInfo.path) {
        this.$router.push(this.modalInfo.path)
      }
      this.closeCommonModal()
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses
    }
  }
}
</script>
