export default {
    namespaced: true,
    mixins: [],
    state: {
        params: {
            device: 'PC',
        }
    },
    actions: {},
    mutations: {
        RESET_PARAMS (state) {
            state.params = {
                device: 'PC'
            }
        },
        SET_PARAMS (state, params) {
            state.params = { ...params }
        }
    },
    getters: {
        params: (state) => {
            return state.params
        }
    }
}
