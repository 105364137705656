import config from '@/config'
import axios from 'axios'
export function msgBoxConfirmOptions() {
  return {
    title: '',
    size: 'sm',
    buttonSize: 'md',
    okVariant: 'primary',
    okTitle: '확인',
    cancelVariant: 'outline-primary',
    cancelTitle: '취소',
    headerClass: 'mt-4',
    contentClass: 'text-center modal-body ',
    footerClass: 'mt-10 text-center',
    hideHeaderClose: true,
    centered: true,
    dialog: true,
    fade: true
  }
}
export function msgBoxOkOptions() {
  return {
    title: '',
    size: 'sm',
    buttonSize: 'md',
    okVariant: 'primary',
    okTitle: '확인',
    headerClass: 'mt-4',
    contentClass: 'text-center modal-body ',
    footerClass: 'mt-10 text-center',
    hideHeaderClose: true,
    centered: true,
    dialog: true,
    fade: true
  }
}

export function loadNCPPayScript() {
  if (window.NCPPay) {
    return Promise.resolve()
  } else {
    return scriptLoader(config.ncpApiBaseUrl + 'payments/' + config.payJs)
  }
}

export function hasClass(elem, cls) {
  cls = cls || ''
  if (cls.replace(/\s/g, '').length === 0) return false
  return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ')
}

export function addClass(elem, cls) {
  if (!hasClass(elem, cls)) {
    elem.className = elem.className === '' ? cls : elem.className + ' ' + cls
  }
}

export function removeClass(elem, cls) {
  if (hasClass(elem, cls)) {
    let newClass = ' ' + elem.className.replace(/[\t\r\n]/g, '') + ' '
    while (newClass.indexOf(' ' + cls + ' ') >= 0) {
      newClass = newClass.replace(' ' + cls + ' ', ' ')
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, '')
  }
}

export function miliFormat(num) {
  return num && num.toString().replace(/(?=(?!^)(\d{3})+$)/g, ',')
}

export function scriptLoader(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.async = true
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  })
}

function isPc(ua) {
  if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
    return true
  }
  return false
}


export function getPlatform() {
  var ua = navigator.userAgent.toLowerCase()
  var isAndroid = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1
  var isIOS = !!ua.match(/\(i[^;]+;( U;)? cpu.+mac os x/)

  if (isIOS) {
    return 'IOS'
  }
  if (isAndroid) {
    return 'AOS'
  }
  if (isPc(ua)) {
    return 'PC'
  }
  return 'MOBILE_WEB'
}

export function getOsType() {
  var ua = navigator.userAgent.toLowerCase()
  var isAndroid = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1
  var isIOS = !!ua.match(/\(i[^;]+;( U;)? cpu.+mac os x/)

  if (isIOS) {
    return 'IOS'
  }
  if (isAndroid) {
    return 'AOS'
  } else {
    return 'WEB'
  }
}

export function gotoInSystemLink(url) {
  if (opener) {
    opener.location.href = url
    self.close()
  } else {
    location.href = url
  }
}

export function validTextLength(text) {
  return text.length
}

export function getDefineName(key, subKey, code, targetNames) {
  if (code === null && subKey !== 'promotionDealTypeData') code = ''
  let data = config.defines[key]
  if (data === undefined) return ''
  let defines = data[subKey]
  if (defines === undefined) return ''
  let findeDefine = defines.filter(function (item) {
    if (item.code === code) {
      return item
    }
  })[0]
  if (findeDefine === undefined) return ''
  if (targetNames) {
    let defineName = ''
    targetNames.forEach(item => {
      defineName += findeDefine[item]
    })
    return defineName
  } else {
    return findeDefine.name
  }
}

export function getSortData(data, key, type) {
  return data.sort(function (a, b) {
    if (type === 'number') {
      return parseInt(a[key]) < parseInt(b[key]) ? -1 : parseInt(a[key]) > parseInt(b[key]) ? 1 : 0
    } else {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
    }
  })
}

export function hourData() {
  return JSON.parse(JSON.stringify(config.defines.commons.hours))
}

export function minuteData() {
  let minute = []
  Array(60)
    .fill()
    .forEach((_, index) => {
      let obj = { id: index, code: index.toString(), name: index < 10 ? '0' + index.toString() : index.toString() }
      minute.push(obj)
    })
  return JSON.parse(JSON.stringify(minute))
}

export function resizeImage({ file, maxWidth, maxHeight }) {
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')
  const resize = () => {
    let { width, height } = image
    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
    } else if (height > maxHeight) {
      width *= maxHeight / height
      height = maxHeight
    }
    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(image, 0, 0, width, height)
    // - set background color -
    // canvas.width = maxWidth
    // canvas.height = maxHeight
    // var top = (maxHeight - height) / 2
    // var left = (maxWidth - width) / 2
    // canvas.getContext('2d').fillStyle = '#000000'
    // canvas.getContext('2d').fillRect(0, 0, maxWidth, maxHeight)
    // canvas.getContext('2d').drawImage(image, left, top, width, height)
    const dataUrl = canvas.toDataURL('image/png')
    // return this.dataURItoBlob(dataUrl)
    return dataUrl
  }
  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'))
      return
    }
    reader.onload = readerEvent => {
      image.onload = () => resolve(resize())
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}

export function excelDownloadReq({ path, params }) {
  const baseUrl = process.env.VUE_APP_BASE_URL
  const url = [baseUrl, path].join('')
  const loading = document.createElement('div')
  loading.classList.add('content_loading')
  const appended = document.body.appendChild(loading)
  
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      responseType: 'blob'
    })
      .then(function (res) {
        const disposition = res.headers['content-disposition']
        let filename = ''
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          let matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]);
          filename = decodeURI(matches[1].replace(/['"]/g, ''))
        }
        let blob = new Blob([res.data])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
        document.body.removeChild(appended)
        resolve(true)
      })
      .catch(error => {
        // console.log(error)
        document.body.removeChild(appended)
        reject(new Error('Server Error'))
      })
  })
}

export function isMobile() {
  let ua = navigator.userAgent.toLowerCase()
  if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
    return false
  }
  return true
}

export function getBaseUrl() {
  let baseUrl = '/'
  let advertiserData = ['skmagic', '11st', 'samsung', 'apple', 'dongwon', 'pg']
  let pathname = window.location.pathname.split('/')
  if (pathname.length >= 2) {
    advertiserData.find(x => {
      if (x === pathname[1]) {
        baseUrl = `/${pathname[1]}/`
      }
    })
  }
  return baseUrl
}
